import React, { Component } from 'react'
import { Link,useNavigate } from 'react-router-dom';


///  changes for authority onluy made to here..
const showFeedback = ((feedback,place) => {
    if (document.getElementById(place) ){
      document.getElementById(place).innerHTML = feedback
    }
  })

export default class SpecOptionsRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    showFeedback('Please refresh url after selecting connection out.'  )      

    return   (     
        <p><Link
   
            onClick={() => {window.location.href = '/use-cubicle/' + this.props.title.AutoID; }}  
            to={'/use-cubicle/' + this.props.title.AutoID}

        >
            {this.props.title.SpecID} -  {this.props.title.Name} &nbsp;   &nbsp;   &nbsp;   &nbsp; <font color="green">{this.props.title.AuthName}</font> 
        </Link></p>
    )       
    
  }
}
