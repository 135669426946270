import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import AssertionList from './components/assertion-list.component'
import CreateAssertion from './components/create-assertion.component'
import EditAssertion from './components/edit-assertion.component'
import Diagrammer from './components/diagrammer.component'
import AuthorityList from './components/authority-list.component'
import Explain from './components/explain.component'
import EditModel from './components/edit-model.component'
import Login from './components/login.component'
import HTMLReport from './components/HTMLReport.component'
import EditFullType from './components/edit-full-type.component'
import UseCubicle from './components/use-cubicle.component'

/*

Steps to deploy:

1. Run build script in FE.   ---  chck for errors   --- now all files (except package.json - dont know why [it can be pasted into the nodjs on deploy])
1.1  it is here:   C:\Users\conte\Documents\_Work\Clammy\Assertionizer\build   -- should rename with suffix  -- it is about 1 Meg
2. in aznr folder on piglet compress ( rclick build folder) ( _work>clammy>assertionizer )
3.Select public_html in iHost   -- it wil put the build.zip in the folder you have selected -  best to put in public_html then rename after
4.Remove aznr05 from public_html and then select your build.zip and extract to /public_html/aznr05  -- build 11 inzipped appears beside the zip
5. Bounce the App using cPanel Nodejs Web Applications
6. AND YOU GET 404??   -- because you get the build with its name popped into the aznr09 dir
7. then move json in
8. then it seems necessary to destroy and rebuild the app in Nodejs WEB APPS   -- destroy takes 2 min
9 for problems ensure that the domain you use points to aznr09 so it can find the app   !!!
10 once created correctly RunNPM Install button will be live - run it
11 does not seem to find package.json?? for aznr10 -  button Run NPM  i greyed
12 Order seemd to be = remove the domain completely. Then recreate -- then add a new app in node.js pointing to public/_HTML.aznr10 and the domain
Then the install npm is available to run - seems to take minutes
13 Delete the node app first - then domain - the recreate domain so it is there to create the node app
14.  running npm seems to take 4 mins -- for v11 this ran smoothlly
https://clammytest.assertions.ca/public/RecMod5/

Deploy 1.05 240711:-  06

Build Script ..  renamed from Build to aznr1_05
Compress to  aznr1_05.zip
Uploaded to public_html
Extract aznr1_05
Copied in package.json
Remove Node App: aznr
Remove Domain aznr
Create Domain  root = aznr1_05
Create NodApp: (AppRoot: public_html/aznr1_05  AppURL: aznr.assertions.ca  AppStartup: index.html)  -- Application Created
Run npm install at 8:36  to  8:39 'Completed Successfully'

*/



function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Navbar   className="fixed-top"    bg="dark" variant="dark">
            <Container>
            <Image src="LOG6Alpha256.png" height="50" width="50" class="img-thumbnail" alt="Assertions Logo"/>
      
              <Navbar.Brand>
                <Link to={'/assertion-list'} className="nav-link">
                  Assertionizer 1.06
                </Link>
              </Navbar.Brand>
              <Nav className="justify-content-end">
  
              <Nav>
                  <Link to={'/model-edit'} className="nav-link">
                    Model
                  </Link>
                </Nav>
                <Nav>
                  <Link to={'/assertion-list'} className="nav-link">
                    Assertions
                  </Link>
                </Nav>
             
                <Nav>
                  <Link to={'/authority-list'} className="nav-link">
                    Authorities
                  </Link>
                </Nav>
                <Nav>
                  <Link to={'/diagrammer'} className="nav-link">
                    Perspectives
                  </Link>
                </Nav>
                <Nav>
                  <Link to={'/login'} className="nav-link">
                    Login
                  </Link>
                </Nav>
                <Nav>
                  <Link
                    className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                    to={'/explain/' + 'KN1'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                  >
                    Explain
                  </Link>
                </Nav>
              </Nav>
            </Container>
          </Navbar>
        </header>
        {/*A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Container>
          <Row>
            <Col md={12}>
              <div className="wrapper">
                <Switch>    
                  <Route
                    exact
                    path="/"
                    component={(props) => <EditModel {...props} />}
                  />
                  <Route
                    exact
                    path="/create-assertion"
                    component={(props) => <CreateAssertion {...props} />}
                  />
                   <Route
                    exact
                    path="/edit-assertion/:id"
                    component={(props) => <EditAssertion {...props} />}
                  />
                  <Route
                    exact
                    path="/use-cubicle/:dbid"
                    component={(props) => <UseCubicle {...props} />}
                  />
                  <Route
                    exact
                    path="/assertion-list"     // is this right   -- does it put in the id??
                    component={(props) => <AssertionList {...props} />}
                  />
                  <Route
                    exact
                    path="/diagrammer"     // is this right   -- does it put in the id??
                    component={(props) => <Diagrammer {...props} />}
                  />
                  <Route
                    exact
                    path="/authority-list"     // is this right   -- does it put in the id??
                    component={(props) => <AuthorityList {...props} />}
                  />
                  <Route
                    exact
                    path="/model-edit"
                    component={(props) => <EditModel {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    component={(props) => <Login {...props} />}
                  />
                   <Route
                    exact
                    path="/explain/:KNID"     
                    component={(props) => <Explain {...props} />}
                  />
                  <Route
                    exact
                    path="/get-html"    
                    component={(props) => <HTMLReport {...props} />}
                  />
                  <Route
                    exact
                    path="/edit-full-type/:typeArgs"
                    component={(props) => <EditFullType {...props} />}
                  />
                </Switch>
              </div>
            </Col>
          </Row>
        </Container>
      </Router>
    </div>
  )
}

export default App
