import React, { Component } from "react";
import { Col, Row, Form} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

import FullConnectionDetail from './FullConnectionDetail';
import InputConnectionTableRow from './InputConnectionTableRow';
import OutputConnectionTableRow from './OutputConnectionTableRow';
import SpecOptionRow from './SpecOptionsRow';
import AuthOptionRow from './AuthOptionsRow';
import AssertionTableRow from './AssertionTableRow';

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
// a utility fn
const showFeedback = ((feedback,place) => {
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})




export default class EditAssertion extends Component {

constructor(props) {
  super(props)

  // State   --- none of this is used
  this.state = {
 
    Name: '',
    SpecID : '',
    Desc: '',
    ActivityType: '',
    Automatic: '',

    Specification: '',
    CurrencyNote : '',
    AttainmentNote: '',
    SizeNote: '',
    Status : '',

    ModelNote : '',
    ImplementationNote: '',
    OutputNote: '',
    ModelLevel: '',
    EffortHours: '',
    YearlyRepeat: '',

    Financial: '',
    Personal: '',
    Record : '',
    Script : '',
    DisplayText : '',

    IsAttainment: '',
    updatedAt: '',
    createdAt: '',
    strAuthGUID: '',
    strGUID: '',
    
    message: 'AOK',
    InputConnections: [],
    OutputConnections: [],
    ListAssertions: [{SpecID: 'sp1',strGUID: 'guuu', Name: 'see me'}],
    ListAuthorities: [
      {strAuthGUID: 'AG93jje', Name: 'Auth1'},
      {strAuthGUID: 'ddfr33', Name: 'Auth2'},
    ],
    strSourceGUID: '',
  }



  this.addInput = this.addInput.bind(this)

  this.onChangeAssertionName = this.onChangeAssertionName.bind(this);
  this.onChangeAssertionSpecID = this.onChangeAssertionSpecID.bind(this);
  this.onChangeAssertionDesc = this.onChangeAssertionDesc.bind(this);
  this.onChangeAssertionActivityType = this.onChangeAssertionActivityType.bind(this);
  this.onChangeAssertionAutomatic = this.onChangeAssertionAutomatic.bind(this);

  this.onChangeAssertionSpecification = this.onChangeAssertionSpecification.bind(this);
  this.onChangeAssertionCurrencyNote  = this.onChangeAssertionCurrencyNote.bind(this);
  this.onChangeAssertionAttainmentNote = this.onChangeAssertionAttainmentNote.bind(this);
  this.onChangeAssertionSizeNote = this.onChangeAssertionSizeNote.bind(this);
  this.onChangeAssertionStatus = this.onChangeAssertionStatus.bind(this);

  this.onChangeAssertionModelNote = this.onChangeAssertionModelNote.bind(this);
  this.onChangeAssertionImplementationNote = this.onChangeAssertionImplementationNote.bind(this);
  this.onChangeAssertionOutputNote = this.onChangeAssertionOutputNote.bind(this);
  this.onChangeAssertionModelLevel = this.onChangeAssertionModelLevel.bind(this);
  this.onChangeAssertionEffortHours = this.onChangeAssertionEffortHours.bind(this);
  this.onChangeAssertionYearlyRepeat = this.onChangeAssertionYearlyRepeat.bind(this);
  

  this.onChangeAssertionFinancial = this.onChangeAssertionFinancial.bind(this);
  this.onChangeAssertionPersonal = this.onChangeAssertionPersonal.bind(this);
  this.onChangeAssertionRecord = this.onChangeAssertionRecord.bind(this);
  this.onChangeAssertionScript = this.onChangeAssertionScript.bind(this);
  this.onChangeAssertionDisplayText = this.onChangeAssertionDisplayText.bind(this);

  this.onChangeAssertionIsAttainment = this.onChangeAssertionIsAttainment.bind(this);
  this.onChangeAssertionLastModifiedDate = this.onChangeAssertionLastModifiedDate.bind(this);
  this.onChangeAssertionCreatedDate = this.onChangeAssertionCreatedDate.bind(this);
  this.onChangeAssertionstrAuthGUID = this.onChangeAssertionstrAuthGUID.bind(this);
  this.onChangeAssertionstrGUID = this.onChangeAssertionstrGUID.bind(this);
  this.onChangeAssertionAutomatic = this.onChangeAssertionAutomatic.bind(this);
  this.onChangePotentialInput = this.onChangePotentialInput.bind(this);
 

  this.onSubmit = this.onSubmit.bind(this);
  this.HandleConnectionDelete = this.HandleConnectionDelete.bind(this);



}

 // ================== COMPONENT MOUNT ====================================================================

componentDidMount() {   // this runs once AFTER first Render
  this.fetchAssertion()  // will cause Rerender if state changes
}
componentDidUpdate() {
  //this.fetchAssertion()   // will cause Rerender if state changes  // NO overkill 

}

fetchAssertion() {

  let strParams = "token=" + localStorage.getItem('USER_MODEL_TOKEN')
  axios.all([     
    axios.get('https://' +  strBEURL + '/assertions/edit-full-assertion/?strID=' + this.props.match.params.id  + '&' + strParams), // i think this tucks all assns
    axios.get('https://' +  strBEURL + '/model/all-authorities/?' + strParams)
  ])
  .then(axios.spread((assns, auths) => {
    // Both requests are now complete
    const res = assns
    const listAssertions = assns.data.assertionNames
    const inputConnections = assns.data.clnConnections.filter((conn) => conn.TargetSpecGUID === res.data.strGUID)
    console.log(assns.data.clnConnections)
    const outputConnections = assns.data.clnConnections.filter((conn) => conn.SourceSpecGUID === res.data.strGUID)
    const listAuthorities = auths.data.payload
    // lets get the aunthname hare 
    const assnauths = listAuthorities.filter((auth) => auth.strGuid === res.data.strAuthGUID)   // cant find a way to get this auth
    let authName = '-not found-'
    if (assnauths) {
      if (assnauths.length) {
        if (assnauths.length > 0 ) {
          authName = assnauths[0].Name
        }
      }
    }
    let selectedPotential = this.state.strSourceGUID    // remember the selected potential input

    this.setState({
      id: res.data.id,
      Name: res.data.Name,
      SpecID: res.data.SpecID, 
      Desc: res.data.Desc,
      ActivityType: res.data.ActivityType,
      Automatic: res.data.Automatic ,   // this stays as text T/F

      Specification: res.data.Specification,
      CurrencyNote: res.data.CurrencyNote, 
      AttainmentNote: res.data.AttainmentNote,
      SizeNote: res.data.SizeNote,
      Status: res.data.Status, 

      ModelNote: res.data.ModelNote, 
      ImplementationNote: res.data.ImplementationNote,
      OutputNote: res.data.OutputNote,
      ModelLevel: res.data.ModelLevel,
      EffortHours: res.data.EffortHours,
      YearlyRepeat: res.data.YearlyRepeat,

      Financial: res.data.Financial,
      Personal: res.data.Personal,
      Record: res.data.Record, 
      Script: res.data.Script, 
      DisplayText : res.data.DisplayText,
      IsAttainment: res.data.IsAttainment,

      createdAt: res.data.createdAt,
      updatedAt: res.data.updatedAt,

      strAuthGUID: res.data.strAuthGUID,
      strGUID: res.data.strGUID,
      message: res.data.strGUID,
  
      InputConnections: inputConnections,
      OutputConnections: outputConnections,
      ListAssertions: listAssertions,  // if this is here can it be updated below
      ListAuthorities: listAuthorities,
      AuthName: authName,   // there will 
      strSourceGUID: selectedPotential,

    });
 
  }))
  return ''
}



 // ================== ONCHANGE FUNCTIONS ====================================================================

  onChangeSpec(event) {
    console.log(event.target.value);
    let guid = event.target.value
    this.setState({
      TargetGUID: guid
    })
  }

  onChangeAssertionName(e) {this.setState({ Name: e.target.value })}
  onChangeAssertionSpecID(e) {this.setState({ SpecID: e.target.value })}
  onChangeAssertionDesc(e) {this.setState({ Desc: e.target.value })}
  onChangeAssertionActivityType(e) {this.setState({ ActivityType: e.target.value })}
 
  onChangeAssertionSpecification(e) {this.setState({ Specification: e.target.value })}
  onChangeAssertionCurrencyNote(e) {this.setState({ CurrencyNote: e.target.value })}
  onChangeAssertionAttainmentNote(e) {this.setState({ AttainmentNote: e.target.value })}
  onChangeAssertionSizeNote(e) {this.setState({ SizeNote: e.target.value })}
  onChangeAssertionStatus(e) {this.setState({ Status: e.target.value })}

  onChangeAssertionModelNote(e) {this.setState({ ModelNote: e.target.value })}
  onChangeAssertionImplementationNote(e) {this.setState({ ImplementationNote: e.target.value })}
  onChangeAssertionOutputNote(e) {this.setState({ OutputNote: e.target.value })}
  onChangeAssertionModelLevel(e) {this.setState({ ModelLevel: e.target.value })}
  onChangeAssertionEffortHours(e) {this.setState({ EffortHours: e.target.value })}
  onChangeAssertionEffortNote(e) {this.setState({ EffortNote: e.target.value })}



  onChangeAssertionYearlyRepeat(e) {this.setState({ YearlyRepeat: e.target.value })}

  onChangeAssertionFinancial(e) {this.setState({Financial: e.target.checked?'TRUE':'FALSE' }) }   // works

  onChangeAssertionPersonal(e) {this.setState({ Personal: e.target.checked?'TRUE':'FALSE' })}
  onChangeAssertionRecord(e) {this.setState({ Record: e.target.checked?'TRUE':'FALSE' })}
  onChangeAssertionScript(e) {this.setState({ Script: e.target.value })}
  onChangeAssertionDisplayText (e) {this.setState({ DisplayText: e.target.value })}

  onChangeAssertionIsAttainment(e) {this.setState({ IsAttainment: e.target.value })}
  onChangeAssertionLastModifiedDate(e) {this.setState({ LastModifiedDate: e.target.value })}
  onChangeAssertionCreatedDate(e) {this.setState({ CreatedDate: e.target.value })}

  onChangeAssertionstrAuthGUID(e) {this.setState({ strAuthGUID: e.target.value })}


  xonChangeAssertionstrAuthGUID(e) {

    let newAuthGUID = this.state.ListAuthorities[e.target.selectedIndex].strGuid
    this.setState({
      strAuthGUID: newAuthGUID       // this statement does not 'take'  nohow    why why  why  
    })
    console.log(this.state.strAuthGUID)
  }
  onChangeAssertionstrGUID(e) {this.setState({ strGUID: e.target.value })}

  //onChangeAuth(e) {this.setState({ strAuthGUID: e.target.value })}

  onChangePotentialInput(e) {
    let keyGUID = this.state.ListAssertions[e.target.selectedIndex].strGUID
    this.setState({ strSourceGUID:  keyGUID })   // this statement does not 'take'  nohow    why why  why  

    showFeedback( " Selected Potential:  " + this.state.ListAssertions[e.target.selectedIndex].Name  + '. With key: ' + keyGUID + '\n Furthermore the state value is: ' + this.state.strSourceGUID,'messagePlace')
  }  // selected potential source


  onChangeAssertionAutomatic(e) {this.setState({Automatic: e.target.checked?'TRUE':'FALSE' }) }   // works



 // ================== RENDER FUNCTIONS ====================================================================

ConnectionTable() {
  return this.state.clnConnections.map((res, i) => {
    return <FullConnectionDetail obj={res} key={i} />;
  });
}

HandleConnectionDelete(){
  this.fetchAssertion()
}

InputConnectionDataTable(obConnections) {
  if (obConnections){
    var arrConnections = Object.values(obConnections)
    return arrConnections.map((res, i) => {
    return <InputConnectionTableRow obj={res} key={i} HandleConnectionDelete={this.HandleConnectionDelete}/>;
    }); 
  }
}

OutputConnectionDataTable(obConnections) {
  if (obConnections){
  var arrConnections = Object.values(obConnections)
    return arrConnections.map((res, i) => {
      return <OutputConnectionTableRow obj={res} key={i} />;
    });
  }
}

FullTextConnectionDetail(obConnections) {
  if (obConnections){
    var arrConnections = Object.values(obConnections)
    return arrConnections.map((res, i) => {
      return <FullConnectionDetail obj={res} key={i} />;
    });
  }
}

OptionList(assns) {
  if(assns){
    let arrAssertions = Object.values(assns)   // obj is the assertion
      return arrAssertions.map((obj, i) => { 
        return <SpecOptionRow obj={obj} key={i} />;
      });
  }
  }

AuthOptionList(auths) {
  if(auths){
    let arrAuths = Object.values(auths)  
      return arrAuths.map((obj, i) => { 
        return <AuthOptionRow obj={obj} key={i} />;
      });
  }
  }

  SetAsnSelected (sel){
    this.setState({
      selectedAsnGUID: sel}
    )
  }
  
  DataTable() {
    return this.state.assertions.map((res, i) => {
      return <AssertionTableRow obj={res} key={i} />;
    });
  }

  // ================== ACTION FUNCTIONS ==================================================================== 


addInput(){
  // An assertion is selected-  add a connection from it to this assertion and refresh
  // get upstream GUID from select box id
  // Need to go get the welected value from the dom 

  let sourceGUID = this.state.strSourceGUID
  let targetGUID = this.state.strGUID
  let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN') 
  strParams += '&SourceSpecGUID=' +  sourceGUID + '&TargetSpecGUID=' +  targetGUID
  //strParams += 'TransmissionType=' +  'TBD' + '&TransmissionNote=' +  'Added from AZNR1' + 'PushPull=' +  'PUSH'

  axios.post('https://' +  strBEURL + '/connections/create-connection/' +  strParams)  // create-connection is ok but gives false messafe
  .then((res) => {
    showFeedback(" Result:  " + res.data,"messagePlace") 
     })
  .catch(function (error) {
    showFeedback(" Error:  " + error,"messagePlace" )
   
  })


  this.fetchAssertion()  // to reget the inputs

}// end Add Input


onSubmit(e) {   // initiated by Update Assertion Button
  e.preventDefault()      // something to do with updating -- but what  -- removing does not solve
  document.getElementById("messagePlace").innerHTML = " Updating :  " + this.state.Name  + "  .... ";
// Axios automatically serializes `{ answer: 42 }` into JSON.

  let strParams = 'id=' + this.state.id + '&Name=' + this.state.Name
  strParams += '&SpecID=' + this.state.SpecID + '&Desc=' + this.state.Desc
  strParams += '&ActivityType=' + this.state.ActivityType + '&Automatic=' + this.state.Automatic
  strParams += '&Specification=' + this.state.Specification + '&CurrencyNote=' + this.state.CurrencyNote
  strParams += '&AttainmentNote=' + this.state.AttainmentNote + '&SizeNote=' + this.state.SizeNote
  strParams += '&Status=' + this.state.Status + '&ModelNote=' + this.state.ModelNote
  strParams += '&ImplementationNote=' + this.state.ImplementationNote + '&OutputNote=' + this.state.OutputNote
  strParams += '&ModelLevel=' + this.state.ModelLevel + '&EffortHours=' + this.state.EffortHours
  strParams += '&YearlyRepeat=' + this.state.YearlyRepeat + '&EffortNote=' + this.state.EffortNote
  strParams += '&Financial=' + this.state.Financial + '&Personal=' + this.state.Personal
  strParams += '&Record=' + this.state.Record        + '&strAuthGUID=' +  this.state.strAuthGUID
  

  showFeedback( ' USING:  https://' +  strBEURL + '/assertions/update-assertion/?' + strParams)

  axios.get('https://' +  strBEURL + '/assertions/update-assertion/?' + strParams ) // should automatically change to json and set headers and put into body
  .then((res) => {
    showFeedback(" UPDATED:  " + this.state.Name,"messagePlace")  // should find the element below??
  })
  .catch(function (error) {
    console.log(error)
    showFeedback( "Failed UPDATE: " + this.state.Name + " with error: "+ error,"messagePlace") // should find the element below??  // this does update when the 
  })
  // is this deprecated
  this.forceUpdate()  //not work

}

handleClick(helpMess) {
  if (document.getElementById("messagePlaceOFF") ){
      showFeedback("...Has it changed?  >" + helpMess,"messagePlace");  //this works
  }
}

  // ================== RENDER ==================================================================== 

  render() {

    let typeArgs = JSON.stringify(
      {strAssnGUID: this.state.strGUID,
        specID: this.state.SpecID,
        Name: this.state.Name,
        AuthName: this.state.AuthName,
        ModelID: this.state.ModelID,  // ensures the modelID is passed down in case a new type has to be created
      }
    )

    return (<div className="form-wrapper">
        <Row>
          <Col>
            <p class="paraModel"><font size="6" color="navy"><b>{this.state.SpecID }  -   {this.state.Name }</b>  </font>&nbsp; &nbsp; &nbsp; <font size="2" color="green"> {this.state.AuthName} </font></p>  
          </Col>  
          <Col>
            <p align="right">
              <Link
                className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}   // path is not defied for Llink and may not be necessary
                to={'/explain/KN2'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                >
                Explain&#62;
              </Link>
              </p>
              </Col>
        </Row>

        <Form  class="form-inline" onSubmit={this.onSubmit}> 
          <Row>
            <Col>  
            <p align="right">
              <font size="1" >{this.state.id} &nbsp;  {this.state.strGUID}</font> &nbsp; <br></br>
              <Button variant="warning"  block="block" type="submit" className="mt-4" >
                  Update Assertion
              </Button> 
              <Link 
                className="edit-link"  //path={"/edit-assertion/:id"}     //   path="/edit-full-type/:idAndName"
                to={`/edit-full-type/`+ typeArgs}
              >
                Edit Frame
              </Link>
              <Link
                className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                to={'/use-cubicle/' + this.state.id}
              >
                Simulate
              </Link>

            </p>
            
            <p align="right"> 

            </p>
          </Col></Row>

          {/*Assertions Section*/} 
          <Row>
          <span class="square border border-4">
          <Col >
          <b>Assertion</b>  
          <Row>
            <Col xs={2}>          
              <Form.Group controlId="SpecID" >
                <Form.Label>Assertion ID
                <Form.Control type="text" value={this.state.SpecID } onChange={this.onChangeAssertionSpecID } onClick={this.handleClick("Text 4 SpecID")}/>
                </Form.Label>
              </Form.Group>
            </Col>
            <Col  xs={6}>         
              <Form.Group  controlId="Name" >
                <Form.Label    >Name 
                  <Form.Control 
                    style={{ fontSize: 20, padding: 3 , color: "navy", width: "550px"}}
                    type="text" 
                    value={this.state.Name } 
                    onChange={this.onChangeAssertionName } 
                    onClick={this.handleClick("Text for Name")}/>
                </Form.Label>
              </Form.Group>
            </Col>
            <Col  xs={3}>         
            <Form.Group  controlId="Auth" >
                <Form.Label   class="float-right" >Authority
                <Form.Select
                  as="select"
                  custom
                  style={{ fontSize: 18, padding: 3 , color: "green", width: "400px"}}
                  value={this.state.strAuthGUID}
                  onChange={this.onChangeAssertionstrAuthGUID}
                >
                  {this.AuthOptionList(this.state.ListAuthorities)}
                </Form.Select>
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="Desc">
              <Form.Label>Description</Form.Label>
              <Col>
                <Form.Control 
                  as="textarea" 
                  value={this.state.Desc }
                  onChange={this.onChangeAssertionDesc }
                  onClick={this.handleClick("Text 4 Desc")}
                  rows={3} /> 
              </Col>
          </Form.Group>
          <Form.Check
            inline
            label="Automation"
            name="Automatic"
            type="checkbox"
            id={`inline-radio`}
            checked={this.state.Automatic === "TRUE"}
            onChange={this.onChangeAssertionAutomatic} 
          />
          </Col>
          </span>
        </Row>      {/*  End Model*/}
      
        <Row>       {/*  Details and Model Row*/}
          <Col>  {/*  Details Col*/}
          <b>Details</b>
            <Row >
              <Col>
                <Form.Group controlId="ActivityType">
                  <Form.Label>Activity Type</Form.Label>
                  <Form.Select
                    as="select"
                    value={this.state.ActivityType} 
                    onChange={this.onChangeAssertionActivityType}
                    custom
                  >
                    <option value="REQUEST">REQUEST</option>
                    <option value="FINDING">FINDING</option>
                    <option value="CONFIGURATION">FINDING</option>
                    <option value="DETECTION">DETECTION</option>
                    <option value="ASSESSMENT">ASSESSMENT</option>
                    <option value="DECISION">DECISION</option>
                    <option value="CONFERENCE">CONFERENCE</option>
                    <option value="CALCULATION">CALCULATION</option>
                    <option value="ACTION">ACTION</option>
                    <option value="CONFIRMATION">CONFIRMATION</option>
                    <option value="CLAIM">CLAIM</option>
                    <option value="EXTRACTION">EXTRACTION</option>

                    <option value="capability">capability</option>
                    <option value="value stream">value stream</option>
                    <option value="product">product</option>
                    <option value="outcome">outcome</option>
                    <option value="value proposition">value proposition</option>
                    <option value="value item">value item</option>
                    <option value="tbd">tbd</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="Model Level">
                  <Form.Label>ModelLevel</Form.Label>
                    <Form.Select
                        as="select"
                        value={this.state.ModelLevel} 
                        onChange={this.onChangeAssertionModelLevel}
                        custom
                      >
                        <option value="OPERATIONAL">OPERATIONAL</option>
                        <option value="GOVERNANCE">GOVERNANCE</option>
                        <option value="EXTERNAL">EXTERNAL</option>
                        <option value="tbd">tbd</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="Specification" >
                <Form.Label>Specification</Form.Label>
                      <Form.Control 
                        type="text"                         
                        as="textarea" 
                        rows={3}   
                        value={this.state.Specification } 
                        onChange={this.onChangeAssertionSpecification } />
            </Form.Group>
            <Form.Group controlId="CurrencyNote" >
                <Form.Label>Currency Note </Form.Label>
                      <Form.Control type="text" value={this.state.CurrencyNote } onChange={this.onChangeAssertionCurrencyNote } />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="EffortHours">
                    <Form.Label>Effort Hours</Form.Label>
                          <Form.Control type="text" value={this.state.EffortHours} onChange={this.onChangeAssertionEffortHours} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="YearlyRepeat">
                    <Form.Label>Yearly Repeat</Form.Label>
                          <Form.Control type="text" value={this.state.YearlyRepeat} onChange={this.onChangeAssertionYearlyRepeat} />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="EffortNote">
                <Form.Label>Effort Note</Form.Label>
                      <Form.Control type="text" value={this.state.EffortNote} onChange={this.onChangeAssertionEffortNote} />
            </Form.Group>
            <Form.Group controlId="SizeNote">
                <Form.Label>Size Note</Form.Label>
                      <Form.Control type="text" value={this.state.SizeNote} onChange={this.onChangeAssertionSizeNote} />
            </Form.Group>
            <div key={`inline-radio`} className="mb-3">
        
              <Form.Check
                inline
                label="Financial"
                name="Financial"
                type="checkbox"
                id={`inline-radio`}
                checked={this.state.Financial === "TRUE"}
                onChange={this.onChangeAssertionFinancial} 
              />
              <Form.Check
                inline
                label="Personal"
                name="Personal"
                type="checkbox"
                id={`inline-radio`}
                checked={this.state.Personal === "TRUE"}
                onChange={this.onChangeAssertionPersonal} 
              />
              <Form.Check
                inline
                name="Record"
                label="Record"
                type="checkbox"
                id={`inline-radio`}
                checked={this.state.Record === "TRUE"}
                onChange={this.onChangeAssertionRecord} 
              />
            </div>
          </Col>  {/** End Details Col */}


          <Col>   {/** Start mod Mgt  Col */}
            <b>Model Management</b>
            <Form.Group controlId="Status" >
                  <Form.Label>Status </Form.Label>
                        <Form.Control type="text" value={this.state.Status } onChange={this.onChangeAssertionStatus } />
            </Form.Group> 
            <Form.Group controlId="Model Note" >
                  <Form.Label>ModelNote </Form.Label>
                        <Form.Control 
                          as="textarea" 
                          rows={3}   
                          value={this.state.ModelNote } 
                          onChange={this.onChangeAssertionModelNote } 
                        />
            </Form.Group> 
            <Form.Group controlId="Implementation Note">
                <Form.Label>ImplementationNote</Form.Label>
                      <Form.Control 
                          as="textarea" 
                          rows={3}  
                        value={this.state.ImplementationNote} 
                        onChange={this.onChangeAssertionImplementationNote} />
            </Form.Group>
            <Row>
              <Col   xs={6}>              
                <Button variant="warning"  block="block" type="submit" className="mt-4">
                              Update Assertion
                </Button>
                <Link 
                  className="edit-link"  
                  to={`/edit-full-type/`+ typeArgs}  //an object in json form
                >
                  Edit Frame
                </Link>
              </Col>     
              <Col   xs={6}>
                <p><Form.Group controlId="LastModifiedDate">
                    <Form.Label>Last Modified Date</Form.Label>
                          <Form.Control type="text" value={this.state.updatedAt} disabled readonly />
                </Form.Group>
                </p>
     
                <Form.Group controlId="Created Date">
                    <Form.Label>Created Date</Form.Label>
                          <Form.Control type="text" value={this.state.createdAt}  disabled readonly/>
                </Form.Group>
              </Col>
            </Row>
      </Col>   {/** End Mod Mgt Col */}
</Row>
     

        <div id="messagePlace" class="alert alert-primary" role="alert">
          messages ..
        </div>

{/*  Connections */}

        <Row>
          <Col><Row>
          <span class="square border border-4">
            <p/>
            <p><b><i><font size="5" color="black">Input Connections</font></i></b></p> 
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>From  Assertion</th>
                  <th>Transmission Type</th>
                  <th>Push/Pull </th>
                  <th>Note</th>
                  <th>  </th>
               
                </tr>
              </thead>
              <tbody>
                {this.InputConnectionDataTable(this.state.InputConnections)}
              </tbody>
            </Table>

            <Row>
              <Col xs={2}>
                <Button onClick={this.addInput} size="sm" variant="primary" id="select-input">
                  Add Input Connection From:   
                </Button>
              </Col>
              <Col xs={8}>
                <Form.Control as="select" custom  onChange={this.onChangePotentialInput}   >
                    {this.OptionList(this.state.ListAssertions)}
                </Form.Control>
              </Col>
            </Row>
          </span>
          </Row><Row>
          <span class="square border border-4">
            <p><b><i><font size="5" color="black">Output Connections</font></i></b></p>  
            {this.OutputConnectionDataTable(this.state.OutputConnections)}
            <Form.Group controlId="OutputNote">
              <Form.Label size="sm" >OutputNote</Form.Label>
                    <Form.Control   size="sm" type="text" value={this.state.OutputNote} onChange={this.onChangeAssertionOutputNote} />
            </Form.Group>
          </span></Row>
          </Col>
        </Row>
   

      </Form>
</div>
    )
}
}