import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {  Row} from "react-bootstrap";

export default class RelationTableRow extends Component {
  constructor(props) {
    super(props)
    //this.deleteAssertion = this.deleteAssertion.bind(this)
// this gets newKNIDHandler as a prop   

  }

  render() {

    function refreshPage() {  // to stop window load before loacation set  - 240709 for some teason this is necessary
      setTimeout(()=>{
          window.location.reload(false);
      }, 1500);
      //console.log('page to reload')
  }


    return (
      <div>
        <Row>
        <span class="square border border-4">
              <p><i>{this.props.obj.strRlnDetails}:</i></p>
                <h5><Link
                  className="nav-link" 
                  onClick={() => {window.location.href = '/explain/' + this.props.obj.strTarKNID }} 
                  to={'/explain/' + this.props.obj.strTarKNID}  
               
                  >
                    {this.props.obj.strTarName}
                </Link></h5>
                <p><Link
                  className="nav-link" 
                  onClick={this.props.newKNIDHandler(this.props.obj.strTarKNID)}  // is this really right  -- should invoke the state change in the parent !!??
                  >
                    Go To {this.props.obj.strTarName}
                </Link></p>
                <p>{this.props.obj.strTarDescription}</p>
                <p><font size="1">{this.props.obj.strTarKNID}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.obj.strRlnCategory}&nbsp;&nbsp;&nbsp;&nbsp;{this.props.obj.strTarCategory}</font></p>
              
         
        </span>
        </Row>
      </div>
    )
  }
}

        //<Link
        //  className="nav-link" 
        //  path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
        //  to={'/explain/' + this.props.obj.strTarKNID}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
        //>
        //</td>  Explore
        //</tr></Link>}